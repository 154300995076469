import { useCallback, useDeferredValue, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { itemHelper, projectHelper, stateHelper, userHelper } from '../../common/src/helpers'
import { useFetch } from '../../common/src/hooks/networkingHooks'
import { projectHandlerInApp as init } from '../../actions/viewInitializationActions'
import { getMatchParameterInteger } from '../../common/src/utils'
import { useSelectedItemId, useSelectedProjectId } from '../../hooks/PMHooks'
import { useAutoSelectProjectEffect } from './ProjectMainViewUtils'
import { 
  setProjectsTagsFilter, 
  setProjectsSortType 
} from '../../common/src/actions/filtersActions'
import { setProjectViewPreference } from '../../actions/uiActions'
import { ProjectMatrixView } from '../matrix/ProjectMatrixView'
import { PMCalendarView } from '../calendar/PMCalendarView'
import { Feed } from '../feed/Feed'
import {
  canGlobalMode,
  getFiltersTypeByMode,
  getProjectModeFromPathname,
  PROJECT_MODE,
} from '../../utils/projectModesUtils'
import { InvalidObjectComponent, useIsObjectInvalidToBeShown } from '../errorViews/InvalidObjectComponent'
import { useItemSortDescriptor } from '../../common/src/hooks/filtersHooks'
import { useApplyFiltersToItems } from '../../hooks/itemHooks'
import { MatrixTableView } from '../matrixTable/MatrixTableView'
import { KanbanView } from '../kanban/KanbanView'
import { GanttView } from '../gantt/v2/GanttView'
import { PMProjectAnalyticsReport } from '../reports/PMProjectAnalyticsReport'
import { useMe } from '../../common/src/hooks/usersHooks.js'

// Force the application of project tags from URL
const ForceApplyTagsFromUrl = ({ filtersType }) => {
  const dispatch = useDispatch();
  
  // Force apply URL parameters right on component mount
  useEffect(() => {
    try {
      // Get URL parameters
      const params = new URLSearchParams(window.location.search);
      const tagParam = params.get('project_tags');
      const sortParam = params.get('project_sort');
      
      if (tagParam) {
        console.warn('[FORCE APPLY] Applying project tags from URL:', tagParam);
        
        // Validate: only allow alphanumeric characters, dashes, and underscores in tag names
        const tagArray = tagParam.split(',')
          .map(tag => tag.trim())
          .filter(tag => /^[a-zA-Z0-9_-]+$/.test(tag));
        
        // Direct dispatch with a slight delay to ensure DOM is ready
        setTimeout(() => {
          dispatch(setProjectsTagsFilter(tagArray, filtersType));
        }, 500);
      }
      
      if (sortParam) {
        console.warn('[FORCE APPLY] Applying project sort from URL:', sortParam);
        // Use a strict whitelist approach for sorting parameters
        const validSortTypes = ['name', 'index', 'timestamp', 'starred', 'pinned'];
        // Only proceed if the sort parameter exactly matches one of our valid options
        if (validSortTypes.includes(sortParam)) {
          setTimeout(() => {
            dispatch(setProjectsSortType(sortParam, filtersType));
          }, 500);
        } else {
          console.warn('Ignored invalid sort parameter:', sortParam);
        }
      }
    } catch (error) {
      console.error('Error in ForceApplyTagsFromUrl:', error);
    }
  }, [dispatch, filtersType]);
  
  return null;
}

export const ProjectMainViewHandler = ({ match, history, location }) => {
  const dispatch = useDispatch()
  const pid = getMatchParameterInteger(match, 'pid')
  const projectMode = getProjectModeFromPathname(location.pathname)
  const filtersType = getFiltersTypeByMode(projectMode)
  
  const me = useMe()
  const selectedItemId = useSelectedItemId()
  const project = useSelector(state => stateHelper.getProject(state, pid))
  const projectItems = useSelector(state => stateHelper.getItemsInProject(state, project))
  const items = useApplyFiltersToItems(projectItems, filtersType)
  const hideOwner =
    projectHelper.getOwnersURIs(project)?.size === 1 &&
    items?.every(item => itemHelper.getOwnerUsername(item) === userHelper.getEmail(me))
  const allProjects = useSelector(stateHelper.getAllProjects)

  const sortFnSync = useItemSortDescriptor(filtersType)
  const sortFn = useDeferredValue(sortFnSync)
  const fetchMethod = useCallback(() => dispatch(init(pid)), [dispatch, pid])
  const { error, loading } = useFetch(fetchMethod)

  const canGlobal = canGlobalMode(projectMode)
  const shouldAutoSelectProject = !canGlobal
  const currentSelectedProjectId = useSelectedProjectId()
  useAutoSelectProjectEffect(shouldAutoSelectProject, pid, currentSelectedProjectId, allProjects, history)
  const isObjectInvalidToBeShown = useIsObjectInvalidToBeShown()

  // Store the current view mode for this project
  useEffect(() => {
    if (pid && projectMode) {
      dispatch(setProjectViewPreference(pid, projectMode))
    }
  }, [pid, projectMode, dispatch])

  const shouldCheckError = !canGlobal
  if (shouldCheckError && !loading && isObjectInvalidToBeShown(project, error)) {
    return <InvalidObjectComponent object={project} error={error} />
  }

  const forwardProps = {
    items,
    project,
    selectedItemId,
    sortFn,
    loading,
    showOwner: !hideOwner,
    filtersType,
  }

  switch (projectMode) {
    case PROJECT_MODE.CALENDAR:
      return (
        <>
          <ForceApplyTagsFromUrl filtersType={filtersType} />
          <PMCalendarView {...forwardProps} />
        </>
      )
    case PROJECT_MODE.FEED:
      return (
        <>
          <ForceApplyTagsFromUrl filtersType={filtersType} />
          <Feed {...forwardProps} />
        </>
      )
    case PROJECT_MODE.GANTT:
      return (
        <>
          <ForceApplyTagsFromUrl filtersType={filtersType} />
          <GanttView {...forwardProps} />
        </>
      )
    case PROJECT_MODE.LIST:
      return (
        <>
          <ForceApplyTagsFromUrl filtersType={filtersType} />
          <MatrixTableView {...forwardProps} />
        </>
      )
    case PROJECT_MODE.KANBAN:
      return (
        <>
          <ForceApplyTagsFromUrl filtersType={filtersType} />
          <KanbanView {...forwardProps} />
        </>
      )
    case PROJECT_MODE.REPORTS:
      forwardProps.items = projectItems
      return (
        <>
          <ForceApplyTagsFromUrl filtersType={filtersType} />
          <PMProjectAnalyticsReport {...forwardProps} />
        </>
      )
    default:
      return (
        <>
          <ForceApplyTagsFromUrl filtersType={filtersType} />
          <ProjectMatrixView {...forwardProps} />
        </>
      )
  }
}
