import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
} from '@fluentui/react-components'
import { useTranslation } from 'react-i18next'
import { useState, useEffect } from 'react'
import { Loading } from '@/components/basic/Loading'
import { isMobile } from '../../common/src/helpers/platformHelper'

export type ReleaseNotesDialogProps = {
  open: boolean
  onClose: () => void
}

export const ReleaseNotesDialog = ({ open, onClose }: ReleaseNotesDialogProps) => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(true)
  const isMobileDevice = isMobile()

  // For mobile devices, navigate directly to the release notes page instead of showing a dialog
  useEffect(() => {
    if (open && isMobileDevice) {
      // Close the dialog immediately since we're navigating away
      onClose()
      // Navigate to the release notes page
      window.open('/release-notes', '_blank')
    }
  }, [open, isMobileDevice, onClose])

  // If on mobile, don't render the dialog content
  if (isMobileDevice) {
    return null
  }

  return (
    <Dialog
      open={open}
      onOpenChange={(event, data) => {
        if (!data.open) onClose()
      }}
    >
      <DialogSurface 
        className="max-h-[95vh] w-[95vw] max-w-[1600px]"
        style={{ minWidth: '80vw', width: '95vw' }}>
        <DialogBody>
          <DialogTitle>{t('left_panel.account.release_notes')}</DialogTitle>
          <DialogContent className="overflow-auto p-0">
            <div className="min-h-[800px] w-full relative">
              {isLoading && (
                <div className="absolute inset-0 flex items-center justify-center">
                  <Loading />
                </div>
              )}
              <iframe
                src="/release-notes"
                className="w-full h-[800px] border-0"
                onLoad={() => setIsLoading(false)}
                title="Release Notes"
                style={{ 
                  backgroundColor: "#f8f9fa",
                  width: "100%",
                  minWidth: "100%"
                }}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <DialogTrigger>
              <Button>{t('general.close')}</Button>
            </DialogTrigger>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  )
}