import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { projectHelper, userHelper } from '../../common/src/helpers'
import { LeftPanelButton } from './LeftPanelButton'
import { getSignOut } from '../../common/src/actions/authAPI'
import { MODE, useAccountOptions, useLanguageOption } from '../../config/account'
import { setIsCancelled } from '../../actions/onboardingActions'
import { useNarrowWidth } from '../../hooks/useNarrowWidth'
import { getRelativeURLKeepingQuerySearch } from '../../helpers/routeHelper'
import { useExtendedHistory } from '../../hooks/useExtendedHistory'
import { useProjectsMap } from '../../common/src/hooks/projectHooks'
import { AMPLITUDE_ACTION_TYPES, dispatchEvent as trackEvent } from '../../common/src/eventTracking/amplitudeEvents'
import { UserCollectionCell } from '../users/usersCollection/UserCollectionCell'
import { VERSION } from '../../common/src/environment'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'
import { isApptest } from '../../helpers/queryParamsHelper'
import { isMobile } from '../../common/src/helpers/platformHelper'
import { KeyboardShortcutListDialog } from '../modal/KeyboardShortcutListDialog'
import { AppearanceSettingsDialog } from '@/components/modal/AppearanceSettingsDialog'
import { ReleaseNotesDialog } from '@/components/modal/ReleaseNotesDialog'
import { useMe } from '@/common/src/hooks/usersHooks'

export const LeftPanelUserButton = ({ tooltip, ...rest }) => {
  const dispatch = useDispatch()
  const me = useMe()
  const narrowWidth = useNarrowWidth()
  const { t } = useTranslation()
  const projects = useProjectsMap()
  const { history } = useExtendedHistory()
  const theme = useTheme()
  const accountOptions = useAccountOptions(MODE.CONTEXT)
  const languageOption = useLanguageOption(MODE.CONTEXT)
  const [keyboardShortcutListDialogOpen, setKeyboardShortcutListDialogOpen] = useState(false)
  const [appearanceSettingsDialogOpen, setAppearanceSettingsDialogOpen] = useState(false)
  const [releaseNotesDialogOpen, setReleaseNotesDialogOpen] = useState(false)

  const onStartTutorial = useCallback(() => {
    if (projects.size >= 1) {
      const p = projects.first()
      const idd = projectHelper.getIdd(p)
      const path = getRelativeURLKeepingQuerySearch.matrixForProjectId(idd)
      history.replace(path)
    }
    dispatch(setIsCancelled(false))
    dispatch(trackEvent(AMPLITUDE_ACTION_TYPES.WEB_APP_ONBOARDING_START))
  }, [dispatch, projects, history])
  const items = [
    ...accountOptions,
    ...(narrowWidth
      ? []
      : [
          {
            key: 'startOnboarding',
            text: t('left_panel.account.start_tutorial'),
            onClick: onStartTutorial,
            iconProps: { iconName: 'D365TalentLearn' },
          },
        ]),
    {
      key: 'appearance_settings',
      name: t('left_panel.account.appearance_settings'),
      iconProps: {
        iconName: 'CSS',
      },
      onClick: () => setAppearanceSettingsDialogOpen(true),
    },
    {
      key: 'keyboard_shortcuts',
      name: t('left_panel.account.keyboard_shortcuts'),
      onClick: () => setKeyboardShortcutListDialogOpen(true),
      iconProps: { iconName: 'KeyboardClassic' },
    },
    languageOption,
    {
      key: 'release_notes',
      name: t('left_panel.account.release_notes'),
      onClick: () => {
        if (isMobile()) {
          // On mobile, open in a new tab directly
          window.open('/release-notes', '_blank')
        } else {
          // On desktop, show the dialog
          setReleaseNotesDialogOpen(true)
        }
      },
      iconProps: { iconName: 'NewsSearch' },
    },
    {
      key: 'signOut',
      text: t('left_panel.account.sign_out'),
      onClick: () => dispatch(getSignOut()),
      iconProps: { iconName: 'SignOut', styles: { root: { color: theme.palette.negative } } },
    },
    {
      key: 'version',
      name: `Version ${VERSION}`,
      disabled: !isApptest(),
      onClick: () => history.push('/version'),
    },
  ]

  const fullName = userHelper.getFullNameWithEmail(me)
  const finalTooltip = tooltip ? tooltip : fullName
  return (
    <>
      <LeftPanelButton
        tooltip={finalTooltip}
        menuProps={{
          items: items,
        }}
        {...rest}
      >
        <UserCollectionCell user={me} hidePersonaDetails />
      </LeftPanelButton>
      <KeyboardShortcutListDialog
        open={keyboardShortcutListDialogOpen}
        onClose={() => setKeyboardShortcutListDialogOpen(false)}
      />
      <AppearanceSettingsDialog
        open={appearanceSettingsDialogOpen}
        onClose={() => setAppearanceSettingsDialogOpen(false)}
      />
      <ReleaseNotesDialog
        open={releaseNotesDialogOpen}
        onClose={() => setReleaseNotesDialogOpen(false)}
      />
    </>
  )
}
