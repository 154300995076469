import _ from 'lodash'
import { MATRIX_MODE, UI_KEYS as KEYS } from '../reducers/uiKeys'

export const isFirstLaunchSelector = state => state.getIn([KEYS.REDUCER, KEYS.IS_FIRST_LAUNCH])

export const getSelectedMeetingId = state => state.getIn([KEYS.REDUCER, KEYS.SELECTION, KEYS.SELECTION_MEETING])
export const getSelectedNotificationId = state =>
  state.getIn([KEYS.REDUCER, KEYS.SELECTION, KEYS.SELECTION_NOTIFICATION])

export const isStateRehydrated = state => state.getIn([KEYS.REDUCER, KEYS.IS_STATE_REHYDRATED])
export const getCommentNotSend = (state, itemID) => state.getIn([KEYS.REDUCER, KEYS.COMMENTS_NOT_SEND, itemID])

export const getViewportScale = state => state.getIn([KEYS.REDUCER, KEYS.VIEWPORT_SCALE])

export const getLastCollaboratorInLocation = (state, location) => state.getIn([KEYS.REDUCER, KEYS.ONE_ON_ONE, location])
export const getRecentlyVisitedItemsIdSet = state => state.getIn([KEYS.REDUCER, KEYS.RECENT, KEYS.RECENT_ITEMS])

export const didToggleItemInMeeting = state =>
  state.getIn([KEYS.REDUCER, KEYS.PER_SESSION, KEYS.DID_TOGGLE_MEETING_ITEM])

const toValidMatrixMode = m => (m === MATRIX_MODE.LIST ? MATRIX_MODE.LIST : MATRIX_MODE.MATRIX)

export const getPreferredMatrixViewMode = _.flow([
  state => {
    const preferred = state.getIn([KEYS.REDUCER, KEYS.PREFERS_MATRIX_MODE])
    return preferred ?? MATRIX_MODE.MATRIX
  },
  toValidMatrixMode,
])

// Get the saved project view preferences mapping (project ID -> view mode)
export const getProjectViewPreferences = state => state.getIn([KEYS.REDUCER, KEYS.PROJECT_VIEW_PREFERENCES])

// Get the preferred view for a specific project
export const getProjectViewPreference = (state, projectId) => 
  state.getIn([KEYS.REDUCER, KEYS.PROJECT_VIEW_PREFERENCES, projectId?.toString()])

export const isEmailMarkedAsInvitedInGrowthView = email => state => {
  const emailSet = state.getIn([KEYS.REDUCER, KEYS.GROWTH_INVITED_EMAIL_SET])
  return emailSet.has(email)
}

export const getShowedCompletedItemHelp = state => state.getIn([KEYS.REDUCER, KEYS.SHOWED_COMPLETED_ITEM_HELP])

export const getShowTrialMessageBar = state => state.getIn([KEYS.REDUCER, KEYS.SHOW_TRIAL_MESSAGE_BAR])
export const getCachedNumberOfProjects = state => state.getIn([KEYS.REDUCER, KEYS.NUMBER_OF_PROJECTS])

export const getHomeAgendaMode = state => state.getIn([KEYS.REDUCER, KEYS.HOME_AGENDA_MODE])
export const getHomeMyItemsMode = state => state.getIn([KEYS.REDUCER, KEYS.HOME_MY_ITEMS_MODE])

export const getItemSelectedInMultipleSelection = itemId => state => {
  const set = state.getIn([KEYS.REDUCER, KEYS.MULTIPLE_SELECTED_ITEMS])
  return set.has(itemId)
}

export const getMultipleSelectedItems = state => state.getIn([KEYS.REDUCER, KEYS.MULTIPLE_SELECTED_ITEMS])

export const isItemMultipleSelectionOn = state => state.getIn([KEYS.REDUCER, KEYS.ITEM_MULTIPLE_SELECTION_ON])

export const getPrefersDarkMode = state => state.getIn([KEYS.REDUCER, KEYS.PREFERS_DARK_MODE])

export const getLastDueTimestampCreated = state => state.getIn([KEYS.REDUCER, KEYS.LAST_DUE_TIMESTAMP_CREATED])
export const getLastStartTimestampCreated = state => state.getIn([KEYS.REDUCER, KEYS.LAST_START_TIMESTAMP_CREATED])
export const getAIMessageDismissed = state => state.getIn([KEYS.REDUCER, KEYS.AI_MESSAGE_DISMISSED])
export const getPMPlatform = state => state.getIn([KEYS.REDUCER, KEYS.PM_PLATFORM])
export const getProjectsPanelShowItemCounts = state => state.getIn([KEYS.REDUCER, KEYS.PROJECTS_PANEL_SHOW_ITEM_COUNTS])
export const getWebinarReminderEnabled = state => state.getIn([KEYS.REDUCER, KEYS.WEBINAR_REMINDER_ENABLED])
export const getLastWebinarIdReminded = state => state.getIn([KEYS.REDUCER, KEYS.LAST_WEBINAR_ID_REMINDED])
export const getLastDismissTrialMessageBarIsoTimestamp = state =>
  state.getIn([KEYS.REDUCER, KEYS.DISMISS_TRIAL_MESSAGE_BAR_ISO_TIMESTAMP])

export const getProjectsDisplayMode = state => state.getIn([KEYS.REDUCER, KEYS.PROJECTS_DISPLAY_MODE])
export const getItemsDisplayMode = state => state.getIn([KEYS.REDUCER, KEYS.ITEMS_DISPLAY_MODE])
export const getCalendarColorMapping = state => state.getIn([KEYS.REDUCER, KEYS.CALENDAR_COLOR_MAPPING])
export const getItemsShowAvatars = state => state.getIn([KEYS.REDUCER, KEYS.ITEMS_SHOW_AVATARS])
