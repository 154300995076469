import { useEffect } from 'react'
import { projectHelper } from '../../common/src/helpers'
import { getRelativeURLKeepingQuerySearch } from '../../helpers/routeHelper'
import { useSelector } from 'react-redux'
import { getProjectViewPreference } from '../../selectors/uiSelectors'

const getNextPath = (currentSelectedProjectId, allProjects, preferredView) => {
  // If we have a current selected project
  if (currentSelectedProjectId) {
    // Use preferred view if available
    if (preferredView && getRelativeURLKeepingQuerySearch[`${preferredView}ForProjectId`]) {
      return getRelativeURLKeepingQuerySearch[`${preferredView}ForProjectId`](currentSelectedProjectId)
    }
    // Otherwise fall back to matrix
    return getRelativeURLKeepingQuerySearch.matrixForProjectId(currentSelectedProjectId)
  }
  
  // No current selected project, use the first one available
  const project = allProjects.first()
  const idd = projectHelper.getIdd(project)
  
  // Check for preferred view for this project
  if (preferredView && getRelativeURLKeepingQuerySearch[`${preferredView}ForProjectId`]) {
    return getRelativeURLKeepingQuerySearch[`${preferredView}ForProjectId`](idd)
  }
  
  // Default to matrix
  return getRelativeURLKeepingQuerySearch.matrixForProjectId(idd)
}

export const useAutoSelectProjectEffect = (shouldAutoSelect, pid, currentSelectedProjectId, allProjects, history) => {
  // Get the preferred view for the current project
  const preferredView = useSelector(state => {
    return currentSelectedProjectId ? getProjectViewPreference(state, currentSelectedProjectId) : null
  })
  
  useEffect(() => {
    if (shouldAutoSelect && !pid && allProjects && allProjects.size) {
      const path = getNextPath(currentSelectedProjectId, allProjects, preferredView)
      history.replace(path)
    }
  }, [pid, allProjects, history, shouldAutoSelect, currentSelectedProjectId, preferredView])
}
