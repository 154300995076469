// src/hooks/useNotificationCounts.js
import { useMemo } from 'react'
import { useAttentionNeededInSession } from '../common/src/hooks/itemHooks'
import { itemHelper } from '../common/src/helpers'

/**
 * Hook that provides counts of different types of notifications in the attention needed items
 * 
 * @returns {Object} Counts of different notification types
 */
export const useNotificationCounts = () => {
  const alerts = useAttentionNeededInSession()
  
  return useMemo(() => {
    const counts = {
      delegation: 0,
      invitation: 0,
      mention: 0,
      overdue: 0,
      other: 0,
      total: alerts.size
    }
    
    alerts.forEach(item => {
      // We need to match the exact logic from useFilteredAlertItems.js
      const notification = itemHelper.getNotification(item)
      const hasUnreadMention = itemHelper.hasUnreadMention(item)
      const dueDate = itemHelper.getDueDate(item)
      const isOverdue = dueDate && dueDate < new Date() && !itemHelper.isCompleted(item)
      const name = itemHelper.getName(item) || ''
      const hasOverdueLabel = name.includes('Overdue')
      const isPaymentItem = name.toLowerCase().includes('payment')
      
      // Apply the exact same filtering logic as in useFilteredAlertItems.js
      // Each item should be counted in exactly one category
      
      // First check special categories in priority order
      if (notification === 'delegation') {
        counts.delegation++
      } else if (notification === 'invitation') {
        counts.invitation++
      } else if (hasUnreadMention) {
        counts.mention++
      } else if (isOverdue || hasOverdueLabel || notification === 'overdue' || notification === 'reminder') {
        counts.overdue++
      } else if (
        // The "other" category logic matches exactly what's in useFilteredAlertItems.js
        isPaymentItem || 
        (notification && 
         notification !== 'delegation' && 
         notification !== 'invitation' && 
         notification !== 'overdue' && 
         notification !== 'reminder' && 
         !hasUnreadMention &&
         !isOverdue &&
         !hasOverdueLabel) ||
        (!notification && !hasUnreadMention && !isOverdue && !hasOverdueLabel)
      ) {
        counts.other++
      }
      // If we somehow didn't match any category, item won't be counted
      // But this shouldn't happen as the conditions cover all possibilities
    })
    
    return counts
  }, [alerts])
}