import React from 'react'
import { Redirect } from 'react-router'
import { OneOnOneView } from '../views/oneOnOne/OneOnOne'
import { NavPanel } from '../components/generic/withNavigation'
import { SearchView } from '../views/search/SearchView'
import { ProjectsListView } from '../views/projects/ProjectsListView'
import { ProjectMainViewHandler } from '../views/projectMainView/ProjectMainViewHandler'
import { InboxView } from '../views/inbox/InboxView'
import { getPathnameKeepingQuerySearch } from '../helpers/routeHelper'
import { VersionView } from '../views/VersionView'
import { OneProjectMainViewHandler } from '../views/projectMainView/OneProjectMainViewHandler'
import { SingleProjectView } from '../views/singleProject/SingleProjectView'
import { SingleItemView } from '../views/singleItem/SingleItemView'
import { HomeView } from '../views/home/HomeView'
import { PMGlobalCalendarView } from '../views/calendar/PMGlobalCalendarView'
import { PublicRoute } from './publicRoute'
import { ROUTE_ID } from './routeIdList'
import { InactivitySignedOut } from '../views/InactivitySignedOut'
import { IndexRedirect } from '../views/redirect/IndexRedirect'
import { FeedTeamsWrapper } from '../views/feed/FeedTeamsWrapper'
import { ResourceRedirectView } from '../views/redirect/ResourceRedirectView'
import { FirstProjectRedirect } from '../views/redirect/FirstProjectRedirect'
import { GrowthView } from '../views/growth/GrowthView'
import { NewProjectModal } from '../views/newProjectModal/NewProjectModal'
import { BlankProjectModal } from '../views/newProjectModal/BlankProjectModal'
import { NewProjectFromCustomTemplateModal } from '../views/newProjectModal/NewProjectFromCustomTemplateModal'
import { PMGlobalReportsView } from '../views/reports/PMGlobalReportsView'
import { NewProjectFromPublicTemplateModal } from '../views/newProjectModal/NewProjectFromPublicTemplateModal'
import { NewCustomProjectTemplateModal } from '../views/newCustomProjectTemplateModal/NewCustomProjectTemplateModal'
import { GraphResourceView } from '../views/graphDetails/GraphResourceView'
import { MeetingView } from '../views/meetings/MeetingView'
import { TeamsMeetingRedirectView } from '../views/meetings/TeamsMeetingRedirectView'
import { PaywallView } from '../views/PaywallView'
import { EffortPlanningView } from '../views/planning/effort/EffortPlanningView'
import { withBusiness } from '../views/withBusiness'
import { LoadingView, StaticLoadingView } from '../views/LoadingView'
import { ProjectPrint } from '../views/project/ProjectPrint'
import { RestoreItemsView } from '../views/project/restoreItems/restoreItemsView'
import { InAppPurchase } from '../views/inapp/InAppPurchase'
import { InAppPurchaseSuccess } from '../views/inapp/InAppPurchaseSuccess'
import { PMGlobalGanttView } from '@/views/gantt/PMGlobalGanttView'

const withReadOnly = Comp => props => <Comp {...props} readOnly />

export const routes = [
  {
    path: '/one_on_one(_v2)?',
    component: [
      {
        path: '/:email?/:id?',
        component: OneOnOneView,
        routeId: ROUTE_ID.ONE_ON_ONE,
      },
    ],
  },
  {
    path: '/app',
    Parent: NavPanel,
    component: [
      {
        path: '/item',
        component: [
          {
            path: '/:id',
            component: SingleItemView,
            routeId: ROUTE_ID.ITEM_APP,
          },
        ],
      },
      {
        path: '/graph_resource',
        component: [
          {
            path: '/:id',
            component: GraphResourceView,
            routeId: ROUTE_ID.GRAPH_RESOURCE_APP,
          },
        ],
      },
      {
        path: '/home',
        component: [
          {
            path: '/:section?',
            component: [
              {
                path: '/:filter?',
                component: [
                  {
                    path: '/item/:id?',  // New explicit route for item selection
                    component: HomeView,
                    routeId: ROUTE_ID.HOME,
                  },
                  {
                    path: '/:id?',       // Keep this for backwards compatibility
                    component: HomeView,
                    routeId: ROUTE_ID.HOME,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: '/project',
        component: [
          {
            path: '/:pid?',
            component: SingleProjectView,
            routeId: ROUTE_ID.PROJECT_APP,
          },
        ],
      },
      {
        path: '/one_on_one(_v2)?',
        component: [
          {
            path: '/:email?/:id?',
            component: OneOnOneView,
            routeId: ROUTE_ID.ONE_ON_ONE_APP,
          },
        ],
      },
      {
        path: '/search/:id?',
        component: SearchView,
        routeId: ROUTE_ID.SEARCH_APP,
      },
      {
        path: '/projects',
        component: [
          {
            path: '/:pid?',
            component: ProjectsListView,
            routeId: ROUTE_ID.PROJECTS,
          },
        ],
      },
      {
        path: '/restore',
        component: [
          {
            path: '/:pid?',
            component: RestoreItemsView,
            routeId: ROUTE_ID.RESTORE_ITEMS_VIEW,
          },
        ],
      },
      {
        path: '/matrix',
        component: [
          {
            path: '/:pid?',
            component: [
              {
                path: '/:id?',
                component: ProjectMainViewHandler,
                routeId: ROUTE_ID.MATRIX_APP,
              },
            ],
          },
        ],
      },
      {
        path: '/kanban',
        component: [
          {
            path: '/:pid?',
            component: [
              {
                path: '/:id?',
                component: ProjectMainViewHandler,
                routeId: ROUTE_ID.KANBAN_APP,
              },
            ],
          },
        ],
      },
      {
        path: '/list',
        component: [
          {
            path: '/:pid?',
            component: [
              {
                path: '/:id?',
                component: ProjectMainViewHandler,
                routeId: ROUTE_ID.LIST_APP,
              },
            ],
          },
        ],
      },
      {
        path: '/print_project',
        component: [
          {
            path: '/:pid?',
            component: [
              {
                path: '/:id?',
                component: ProjectPrint,
                routeId: ROUTE_ID.PRINT_PROJECT,
              },
            ],
          },
        ],
      },
      {
        path: '/calendar',
        component: [
          {
            path: '/:pid',
            component: [
              {
                path: '/:id?',
                component: ProjectMainViewHandler,
                routeId: ROUTE_ID.CALENDAR_APP,
              },
            ],
          },
        ],
      },
      {
        path: '/gantt',
        component: [
          {
            path: '/:pid',
            component: [
              {
                path: '/:id?',
                component: ProjectMainViewHandler,
                routeId: ROUTE_ID.GANTT_APP,
              },
            ],
          },
        ],
      },
      {
        path: '/inbox',
        component: [
          {
            path: '/:id?',
            component: InboxView,
            routeId: ROUTE_ID.INBOX,
          },
        ],
      },
      {
        path: '/feed',
        component: [
          {
            path: '/:pid?',
            component: [
              {
                path: '/:id?',
                component: ProjectMainViewHandler,
                routeId: ROUTE_ID.FEED_APP,
              },
            ],
          },
        ],
      },
      {
        path: '/reports',
        component: [
          {
            path: '/:pid?',
            component: [
              {
                path: '/:id?',
                component: ProjectMainViewHandler,
                routeId: ROUTE_ID.REPORTS_APP,
              },
            ],
          },
        ],
      },
      {
        path: '/global_feed',
        component: [
          {
            path: '/:id?',
            component: ProjectMainViewHandler,
            routeId: ROUTE_ID.GLOBAL_FEED_APP,
          },
        ],
      },
      {
        path: '/global_calendar',
        component: [
          {
            path: '/:id?',
            component: PMGlobalCalendarView,
            routeId: ROUTE_ID.GLOBAL_CALENDAR_APP,
          },
        ],
      },
      {
        path: '/global_reports/:id?',
        component: PMGlobalReportsView,
        routeId: ROUTE_ID.GLOBAL_REPORTS_APP,
      },
      {
        path: '/global_gantt',
        component: [
          {
            path: '/:id?',
            component: withBusiness(PMGlobalGanttView),
            routeId: ROUTE_ID.GLOBAL_GANTT_APP,
          },
        ],
      },
      {
        path: '/new_project/blank',
        exact: true,
        component: BlankProjectModal,
        routeId: ROUTE_ID.BLANK_PROJECT_APP,
      },
      {
        path: '/new_project/custom_template/:template_id',
        exact: true,
        component: NewProjectFromCustomTemplateModal,
        routeId: ROUTE_ID.NEW_PROJECT_FROM_CUSTOM_TEMPLATE_APP,
      },
      {
        path: '/new_project/public_template/:template_id',
        exact: true,
        component: NewProjectFromPublicTemplateModal,
        routeId: ROUTE_ID.NEW_PROJECT_FROM_PUBLIC_TEMPLATE_APP,
      },
      {
        path: '/new_project/:section?',
        component: NewProjectModal,
        routeId: ROUTE_ID.NEW_PROJECT_APP,
      },
      {
        path: '/new_custom_project_template/:pid',
        exact: true,
        component: NewCustomProjectTemplateModal,
        routeId: ROUTE_ID.NEW_CUSTOM_PROJECT_TEMPLATE_APP,
      },
      {
        path: '/planning',
        component: [
          {
            path: '/effort/:id?',
            component: withBusiness(EffortPlanningView),
            routeId: ROUTE_ID.EFFORT_PLANNING,
          },
        ],
      },
      {
        path: '/',
        exact: true,
        render: () => {
          const to = getPathnameKeepingQuerySearch('/app/matrix/')
          return <Redirect to={to} />
        },
      },
    ],
  },
  {
    path: '/search/:id?',
    component: SearchView,
    routeId: ROUTE_ID.SEARCH,
  },
  {
    path: '/version',
    component: VersionView,
    route: PublicRoute,
    routeId: ROUTE_ID.VERSION,
  },
  {
    path: '/release-notes',
    component: VersionView,
    route: PublicRoute,
    routeId: ROUTE_ID.RELEASE_NOTES,
  },
  {
    path: '/loading-static',
    component: StaticLoadingView,
    route: PublicRoute,
    routeId: ROUTE_ID.LOADING_STATIC,
  },
  {
    path: '/loading',
    component: LoadingView,
    route: PublicRoute,
    routeId: ROUTE_ID.LOADING,
  },
  {
    path: '/matrix',
    component: [
      {
        path: '/:pid?',
        component: [
          {
            path: '/:id?',
            component: OneProjectMainViewHandler,
            routeId: ROUTE_ID.MATRIX_ONE,
          },
        ],
      },
    ],
  },
  {
    path: '/list',
    component: [
      {
        path: '/:pid?',
        component: [
          {
            path: '/:id?',
            component: OneProjectMainViewHandler,
            routeId: ROUTE_ID.LIST_ONE,
          },
        ],
      },
    ],
  },
  {
    path: '/kanban',
    component: [
      {
        path: '/:pid?',
        component: [
          {
            path: '/:id?',
            component: OneProjectMainViewHandler,
            routeId: ROUTE_ID.KANBAN_ONE,
          },
        ],
      },
    ],
  },
  {
    path: '/calendar',
    component: [
      {
        path: '/:pid?',
        component: [
          {
            path: '/:id?',
            component: OneProjectMainViewHandler,
            routeId: ROUTE_ID.CALENDAR_ONE,
          },
        ],
      },
    ],
  },
  {
    path: '/gantt',
    component: [
      {
        path: '/:pid?',
        component: [
          {
            path: '/:id?',
            component: OneProjectMainViewHandler,
            routeId: ROUTE_ID.GANTT_ONE,
          },
        ],
      },
    ],
  },
  {
    path: '/feed',
    component: [
      {
        path: '/:pid?',
        component: [
          {
            path: '/:id?',
            component: OneProjectMainViewHandler,
            routeId: ROUTE_ID.FEED_ONE,
          },
        ],
      },
    ],
  },
  {
    path: '/reports',
    component: [
      {
        path: '/:pid?',
        component: [
          {
            path: '/:id?',
            component: OneProjectMainViewHandler,
            routeId: ROUTE_ID.REPORTS_ONE,
          },
        ],
      },
    ],
  },
  {
    path: '/read_only',
    route: PublicRoute,
    component: [
      {
        path: '/matrix',
        route: PublicRoute,
        component: [
          {
            path: '/:pid?',
            route: PublicRoute,
            component: [
              {
                path: '/:id?',
                route: PublicRoute,
                component: OneProjectMainViewHandler,
                routeId: ROUTE_ID.MATRIX_READ_ONLY,
              },
            ],
          },
        ],
      },
      {
        path: '/list',
        route: PublicRoute,
        component: [
          {
            path: '/:pid?',
            route: PublicRoute,
            component: [
              {
                path: '/:id?',
                route: PublicRoute,
                component: OneProjectMainViewHandler,
                routeId: ROUTE_ID.LIST_READ_ONLY,
              },
            ],
          },
        ],
      },
      {
        path: '/kanban',
        route: PublicRoute,
        component: [
          {
            path: '/:pid?',
            route: PublicRoute,
            component: [
              {
                path: '/:id?',
                route: PublicRoute,
                component: OneProjectMainViewHandler,
                routeId: ROUTE_ID.KANBAN_READ_ONLY,
              },
            ],
          },
        ],
      },
      {
        path: '/calendar',
        route: PublicRoute,
        component: [
          {
            path: '/:pid?',
            route: PublicRoute,
            component: [
              {
                path: '/:id?',
                route: PublicRoute,
                component: OneProjectMainViewHandler,
                routeId: ROUTE_ID.CALENDAR_READ_ONLY,
              },
            ],
          },
        ],
      },
      {
        path: '/gantt',
        route: PublicRoute,
        component: [
          {
            path: '/:pid?',
            route: PublicRoute,
            component: [
              {
                path: '/:id?',
                route: PublicRoute,
                component: OneProjectMainViewHandler,
                routeId: ROUTE_ID.GANTT_READ_ONLY,
              },
            ],
          },
        ],
      },
      {
        path: '/item',
        route: PublicRoute,
        component: [
          {
            path: '/:id?',
            component: withReadOnly(SingleItemView),
            route: PublicRoute,
            routeId: ROUTE_ID.SINGLE_ITEM_READ_ONLY,
          },
        ],
      },
    ],
  },
  {
    path: '/project',
    component: [
      {
        path: '/:pid?',
        component: SingleProjectView,
        routeId: ROUTE_ID.SINGLE_PROJECT,
      },
    ],
  },
  {
    path: '/item',
    component: [
      {
        path: '/:id?',
        component: SingleItemView,
        routeId: ROUTE_ID.SINGLE_ITEM,
      },
    ],
  },
  {
    path: '/teams',
    component: [
      {
        path: '/calendar',
        component: [
          {
            path: '/:id?',
            component: PMGlobalCalendarView,
            routeId: ROUTE_ID.CALENDAR_TEAMS,
          },
        ],
      },
      {
        path: '/feed',
        component: [
          {
            path: '/:id?',
            component: FeedTeamsWrapper,
            routeId: ROUTE_ID.FEED_TEAMS,
          },
        ],
      },
      {
        path: '/meeting',
        component: TeamsMeetingRedirectView,
        routeId: ROUTE_ID.TEAMS_MEETING,
      },
    ],
  },
  {
    path: '/meeting/:meetingid/:id?',
    component: MeetingView,
    routeId: ROUTE_ID.MEETING,
  },
  {
    path: '/redirect',
    component: [
      {
        path: '/firstProject',
        component: FirstProjectRedirect,
        routeId: ROUTE_ID.REDIRECT_FIRST_PROJECT,
      },
      {
        path: '/:resource/:id',
        exact: true,
        component: ResourceRedirectView,
        routeId: ROUTE_ID.REDIRECT,
      },
    ],
  },
  {
    path: '/growth',
    exact: true,
    component: GrowthView,
    routeId: ROUTE_ID.GROWTH,
  },
  {
    path: '/paywall',
    exact: true,
    component: PaywallView,
    excludePromotions: true,
    routeId: ROUTE_ID.PAYWALL,
  },
  {
    path: '/public',
    component: [
      {
        path: '/inapp',
        component: [
          {
            path: '/macos',
            route: PublicRoute,
            component: InAppPurchase,
            routeId: ROUTE_ID.IN_APP_PURCHASE_MACOS,
          },
          {
            path: '/success',
            route: PublicRoute,
            component: InAppPurchaseSuccess,
            routeId: ROUTE_ID.IN_APP_PURCHASE_SUCCESS,
          },
        ],
      },
    ],
  },
  {
    path: '/inactivity_signed_out',
    route: PublicRoute,
    component: InactivitySignedOut,
    routeId: ROUTE_ID.INNACTIVITY_SIGNED_OUT,
  },
  {
    path: '/',
    exact: true,
    component: IndexRedirect,
    excludePromotions: true,
  },
]
