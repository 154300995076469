import { useMemo } from 'react'
import { Redirect } from 'react-router'
import { useProjectsMap } from '../../common/src/hooks/projectHooks'
import { projectHelper } from '../../common/src/helpers'
import { getRelativeURLKeepingQuerySearch } from '../../helpers/routeHelper'
import { useSelector } from 'react-redux'
import { resourceRequestSelectors } from '../../common/src/selectors'
import { caseInsensitiveIncludes } from '../../common/src/helpers/stringHelper'
import { getProjectViewPreference } from '../../selectors/uiSelectors'

const PROJECTS_LOADING = 'projects_loading'

export const FirstProjectRedirect = () => {
  const projects = useProjectsMap()
  const didFetchProjects = useSelector(resourceRequestSelectors.didFetProjectSummaries)
  const projectViewPreferences = useSelector(state => state) // Get the entire state for later use

  const projectToRedirect = useMemo(() => {
    if (!didFetchProjects || !projects) {
      return PROJECTS_LOADING
    }
    const matchesTeamProject = caseInsensitiveIncludes('team project')
    const matchesMyProject = caseInsensitiveIncludes('my project')
    const preferredProjectsArray = [
      projects.find(p => {
        const name = projectHelper.getName(p)
        return matchesTeamProject(name)
      }),
      projects.find(p => {
        const name = projectHelper.getName(p)
        return matchesMyProject(name)
      }),
      projects.first(),
    ]
    return preferredProjectsArray.find(Boolean)
  }, [didFetchProjects, projects])

  // Determine the redirect path based on the project to redirect to
  const getRedirectPath = () => {
    if (projectToRedirect === PROJECTS_LOADING) {
      return null; // Loading state, will be handled separately
    }

    if (projectToRedirect) {
      const projectIdd = projectHelper.getIdd(projectToRedirect)
      // Get the preferred view from the state we already have
      const preferredView = getProjectViewPreference(projectViewPreferences, projectIdd)
      
      // Use the stored preference if available, otherwise default to matrix
      if (preferredView && getRelativeURLKeepingQuerySearch[`${preferredView}ForProjectId`]) {
        return getRelativeURLKeepingQuerySearch[`${preferredView}ForProjectId`](projectIdd);
      }
      return getRelativeURLKeepingQuerySearch.matrixForProjectId(projectIdd);
    }

    // No project to redirect to, go to home
    return getRelativeURLKeepingQuerySearch.homeSection();
  };

  const redirectPath = getRedirectPath();

  if (projectToRedirect === PROJECTS_LOADING) {
    return 'Redirecting...';
  }

  return <Redirect to={redirectPath} />;
}
